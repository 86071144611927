import styled from 'styled-components';

import { thingosColors } from '../../../shared/src/Theme/theme';
import { colors } from '../styles/variables';

export const Button = styled.a<{ color?: string; small?: boolean }>(({ color, small }) => ({
	paddingLeft: '20px',
	paddingRight: '20px',
	height: 40,
	display: 'flex',
	position: 'relative',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: color ? color : thingosColors.highlight.main,
	fontFamily: 'Roboto',
	fontWeight: 500,
	fontSize: small ? '0.8rem' : '1.125rem',
	color: colors.white,
	zIndex: 100,
	borderRadius: '4px',
	transition: '200ms ease-in-out',
	boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
	cursor: 'pointer',
	'&:hover': {
		textDecoration: 'none',
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
	},
	'&:focus': {
		textDecoration: 'none',
	},
}));

export const SecondaryButton = styled(Button)({
	backgroundColor: 'white',
	borderColor: '#265ebf',
	borderWidth: 5,
	color: 'black',
});
