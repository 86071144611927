import { Link } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

import { useIsAtTop } from '@thingos/thingos-homepage-shared';

import { colors, dimensions } from '../styles/variables';
import Container from './Container';
import Menu from './Menu';

import Logo from '../svgs/logo.svg';

const HeaderStyled = styled.header<{ isAtTop?: boolean }>(props => ({
	position: 'fixed',
	top: 0,
	width: '100%',
	zIndex: 1001,
	padding: props.isAtTop
		? `1rem ${dimensions.containerPadding}rem`
		: `0.5rem ${dimensions.containerPadding}rem`,
	backgroundColor: colors.white,
	borderBottom: '1px hsla(0, 0%, 83.9%, 1) solid',
	transform: 'translate3d(0,0,0)',
	transition: '0.5s ease',
}));

const HeaderInner = styled(Container)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	height: '100%',
	'@media only screen and (max-width: 600px)': {
		alignItems: 'flex-start',
	},
});

const HomepageLink = styled(Link)({
	color: colors.black,
	'&:hover': {
		textDecoration: 'none',
	},
	'&:focus': {
		textDecoration: 'none',
	},
});

const HeaderLogo = styled.img({
	height: 30,
});

interface HeaderProps {
	title: string;
}

const Header: React.FC<HeaderProps> = () => {
	const [isAtTop] = useIsAtTop();

	return (
		<HeaderStyled isAtTop={isAtTop}>
			<HeaderInner>
				<HomepageLink to="/">
					<HeaderLogo src={Logo} />
				</HomepageLink>
				<Menu />
			</HeaderInner>
		</HeaderStyled>
	);
};

export default Header;
