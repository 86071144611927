import { HStack, VStack } from '@domir/react-stacks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import { FooterBar, FooterSiteMap, SocialIcon } from '@thingos/thingos-homepage-shared';

import whiteThingOSLogo from '../svgs/logoWhite.svg';

export const ThingOSFooter: React.FC = () => {
	const { t } = useTranslation('translations');

	return (
		<VStack>
			<FooterSiteMap.Container>
				<FooterSiteMap.Column>
					<FooterSiteMap.Header>{t('footer.brands.title')}</FooterSiteMap.Header>
					<FooterSiteMap.LocalLink to="/">ThingOS</FooterSiteMap.LocalLink>
					<FooterSiteMap.ExternalLink href="https://mesh-lighting.com/" target="_blank">
						Meshlighting
					</FooterSiteMap.ExternalLink>
				</FooterSiteMap.Column>
				<FooterSiteMap.Column>
					<FooterSiteMap.Header>{t('footer.career.title')}</FooterSiteMap.Header>
					<FooterSiteMap.LocalLink to="/about-us">
						{t('footer.career.about')}
					</FooterSiteMap.LocalLink>
					<FooterSiteMap.LocalLink to="/jobs">{t('footer.career.jobs')}</FooterSiteMap.LocalLink>
				</FooterSiteMap.Column>
				<FooterSiteMap.Column>
					<FooterSiteMap.Header>{t('footer.contact.title')}</FooterSiteMap.Header>
					<FooterSiteMap.ExternalLink href="mailto:info@thingos.io" target="_blank">
						<SocialIcon kind="services/email" style={{ marginRight: '8px' }} />
						{t('footer.contact.contact')}
					</FooterSiteMap.ExternalLink>
				</FooterSiteMap.Column>
				<FooterSiteMap.Column>
					<FooterSiteMap.Header>{t('footer.social.title')}</FooterSiteMap.Header>
					<FooterSiteMap.ExternalLink href="https://www.linkedin.com/company/thingos/">
						<SocialIcon kind="services/linkedIn" style={{ marginRight: '8px' }} />
						LinkedIn
					</FooterSiteMap.ExternalLink>
					<FooterSiteMap.ExternalLink href="https://www.facebook.com/meshlighting">
						<SocialIcon kind="services/facebook" style={{ marginRight: '8px' }} />
						Facebook
					</FooterSiteMap.ExternalLink>
					<FooterSiteMap.ExternalLink href="https://twitter.com/thingosio">
						<SocialIcon kind="services/twitter" style={{ marginRight: '8px' }} />
						Twitter
					</FooterSiteMap.ExternalLink>
					<FooterSiteMap.ExternalLink href="https://www.youtube.com/channel/UC-FZLhhRwlXvJEcWdZ8-uoQ">
						<SocialIcon kind="services/youtube" style={{ marginRight: '8px' }} />
						Youtube
					</FooterSiteMap.ExternalLink>
				</FooterSiteMap.Column>
			</FooterSiteMap.Container>

			<FooterBar.Container>
				<FooterBar.Logo src={whiteThingOSLogo} />
				<HStack spacing="1rem" alignItems="center">
					<FooterBar.Text>{t('footer.copyright')}</FooterBar.Text>
					<FooterBar.Link to="/impressum">{t('footer.imprint')}</FooterBar.Link>
					<FooterBar.Link to="/impressum">{t('footer.privacy')}</FooterBar.Link>
				</HStack>
			</FooterBar.Container>
		</VStack>
	);
};
