import { Helmet } from 'gatsby-plugin-react-i18next';
import 'modern-normalize';
import React from 'react';
import styled from 'styled-components';
import 'typeface-inter';
import 'typeface-roboto';

import { Theme } from '@thingos/thingos-homepage-shared';

import Header from '../components/Header';
import { ThingOSFooter } from '../components/ThingOSFooter';
import { StoreContext, stores } from '../stores';
import { GlobalStyle } from '../styles/normalize';

const GlobalThemeStyle = Theme.globalStyle(Theme.thingosColors);

const LayoutRoot = styled.div({
	width: '100%',
	minHeight: '100vh',
	overflow: 'auto',
	display: 'flex',
	flexDirection: 'column',
	right: '-17px' /* Increase/Decrease this value for cross-browser compatibility */,
});

const LayoutMain = styled.main({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	marginTop: 70,
});

// see more helmet options: https://github.com/microapps/gatsby-plugin-react-i18next/blob/3ff0d295c30044c1a86f0829a41062673f3a1be1/example/src/components/seo.js
export const IndexLayout: React.FC = props => {
	const { children } = props;

	return (
		<StoreContext.Provider value={stores}>
			<LayoutRoot>
				<GlobalStyle />
				<GlobalThemeStyle />
				<Helmet
					title="ThingOS"
					meta={[
						{ name: 'description', content: 'The smart things integrator' },
						{ name: 'keywords', content: 'iot, thingos, nodejs, automation, home' },
					]}
				/>
				<Header title="ThingOS" />
				<LayoutMain>{children}</LayoutMain>
				<ThingOSFooter />
			</LayoutRoot>
		</StoreContext.Provider>
	);
};
