import { GatsbyLinkProps, Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { observer } from 'mobx-react';
import * as React from 'react';
import { GrClose, GrMenu } from 'react-icons/gr';
import styled from 'styled-components';

import { thingosColors } from '../../../shared/src/Theme/theme';
import { useMedia } from '../hooks/media';
import { useMenuStore } from '../hooks/stores';
import { Button } from './Button';

const MenuContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	height: '100%',
	'& :not(:first-child)': {
		marginLeft: '40px',
	},
	'@media only screen and (max-width: 600px)': {
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
});

export const MenuLink = styled(Link)({
	color: thingosColors.text.primary,
	fontWeight: 500,
	fontSize: '0.8rem',
	'&:hover': {
		textDecoration: 'none',
		color: thingosColors.highlight.secondary,
	},
	'&:focus': {
		textDecoration: 'none',
	},
	'@media only screen and (max-width: 600px)': {
		marginTop: 8,
		marginBottom: 8,
	},
});

export const MenuLinkExternal = styled.a({
	color: thingosColors.text.primary,
	fontWeight: 500,
	fontSize: '0.8rem',
	'&:hover': {
		textDecoration: 'none',
		color: thingosColors.highlight.secondary,
	},
	'&:focus': {
		textDecoration: 'none',
	},
	'@media only screen and (max-width: 600px)': {
		marginTop: 8,
		marginBottom: 8,
	},
});

const MenuItem: React.FC<Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>> = props => {
	const menuStore = useMenuStore();
	return <MenuLink onClick={menuStore.onPressToggleMenu} {...props} />;
};

const MenuButton = styled.button({
	border: 'none',
	backgroundColor: '#000',
	background: 'none',
	'&:focus': {
		outline: 'none',
	},
});

const Menu: React.FC = observer(() => {
	const menuStore = useMenuStore();
	const media = useMedia();
	const { t } = useTranslation('translations');

	return (
		<MenuContainer>
			{media.small && (
				<MenuButton role="button" onClick={menuStore.onPressToggleMenu}>
					{menuStore.isMenuOpen ? <GrClose /> : <GrMenu />}
				</MenuButton>
			)}
			{(!media.small || menuStore.isMenuOpen) && (
				<>
					<MenuItem to="/">{t('menu.item1')}</MenuItem>
					<MenuItem to="/products">{t('menu.item2')}</MenuItem>
					<MenuItem to="/solutions">{t('menu.item3')}</MenuItem>
					<MenuLinkExternal href="https://mesh-lighting.de" target="_blank">
						{t('menu.item4')}
					</MenuLinkExternal>
					<MenuItem to="/industries">{t('menu.item5')}</MenuItem>
					<MenuItem to="/about-us">{t('menu.item6')}</MenuItem>
					<MenuItem to="/jobs">{t('menu.item7')}</MenuItem>
					{/* <MenuItem to="/contact">Contact</MenuItem> */}
					<Button small href="/contact">
						{t('menu.button')}
					</Button>
				</>
			)}
		</MenuContainer>
	);
});

export default Menu;
