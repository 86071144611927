/**
 * Hook to allow checking if the user is at the top of the page.
 *
 * @author Dominique Rau [domi.rau@gmail.com](mailto:domi.rau@gmail.com)<br/>
 * @version 0.0.1
 * @see https://raw.githubusercontent.com/Semantic-Org/Semantic-UI/master/src/definitions/behaviors/visibility.js
 */
import * as React from 'react';

export const useIsAtTop = () => {
	const [isAtTop, setIsAtTop] = React.useState(true);

	React.useEffect(() => {
		function checkIsAtTop() {
			const scrollTopChromeSafari = document.body.scrollTop || 0;
			const scrollTopFirefox = document.documentElement.scrollTop || 0;
			const scrollTop = Math.max(scrollTopChromeSafari, scrollTopFirefox);
			if (scrollTop > 15 && isAtTop) {
				setIsAtTop(false);
			} else if (scrollTop <= 15) {
				setIsAtTop(true);
			}
		}

		window.addEventListener('scroll', checkIsAtTop);
		checkIsAtTop();

		return () => {
			window.removeEventListener('scroll', checkIsAtTop);
		};
	}, [setIsAtTop]);

	return [isAtTop];
};
